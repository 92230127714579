import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {WithChildren} from '../../../../_metronic/helpers'
import {useCallApi} from '../../utils'
import {useParams} from 'react-router-dom'
import { boolean, number } from 'yup'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  products:any
  categories: any
  dataUpdated: any,
  setDataUpdated: (object: any) => void
  dataSaleUpdated: any,
  setDataSaleUpdated: (object: any) => void,
  comptabilise: any
  setComptabilise: (object: any) => void
  date: any
  numCmd:any,
  getProducts:()=>void,
  getCategories:()=>void,
  getInventories:()=>void,
  hasPermission: (permission: string) => boolean; // Update the return type to boolean
  setNumCmd:(num:any)=>void,
  setDate: (object: any) => void
  clients:any,
  currentPage:number,
  totalPages:number,
  totalItems:number,
  setCurrentPage:(object: any) =>void,
  setTotalPages:(object: any) => void,
  setTotalItems:(object: any) => void,
  getBrands:()=>void,
  getSuppliers:()=>void,
  brands:any,
  suppliers:any,
tab:String,
setTab:(object:string)=>void

}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  hasPermission: () => false,
  categories: [],
  getCategories:()=>{},
  getInventories:()=>{},
  products:[],
  tab:"",
  setTab:(object:string)=>{},
  clients:[],
  dataUpdated: [],
  setDataUpdated: (object: any) => {},
  dataSaleUpdated: [],
  setDataSaleUpdated: (object: any) => {},
  comptabilise: "",
  setComptabilise: () => {},
  brands: [],
  getBrands:()=>{},
  suppliers: [],
  getSuppliers:()=>{},
  date: '',
  numCmd:"",
  getProducts:()=>{},
  setNumCmd:(num:any)=>{},
  setDate: (object: any) => {},
  currentPage:1,
  totalPages:1,
  totalItems:0,
  setCurrentPage:(object: any) => {},
  setTotalPages:(object: any) => {},
  setTotalItems:(object: any) => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const {id} = useParams()
  const [comptabilise, setComptabilise] = useState<any>("")
  const currentDate = new Date()
  const formattedDate = currentDate.toLocaleDateString('en-CA')
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  
  const [totalItems, setTotalItems] = useState(0);
  const [date, setDate] = useState(formattedDate)

  const [categories, setCategories] = useState<any>([])
  const [inventories, setInventories] = useState<any>([])

  const [products, setProducts] = useState<any>([])
  const [clients, setClients] = useState<any>([])
  const [brands, setBrands] = useState<any>([])
  const [suppliers, setSuppliers] = useState<any>([])
  const [numCmd, setNumCmd] = useState("")
  const [tab, setTab] = useState<string>("day")
  const [dataUpdated, setDataUpdated] = useState<any>({
    id: null,
    date: '',
    warehouse_id: null,
    supplier_id: null,
    discount: 0,
    tax_rate: 0,
    type:true,
    purchase_items: [
      {
        discount_amount: 0,
        discount_type: '1',
        discount_value: 0,
        net_unit_cost: 0,
        tax_amount: 0,
        tax_type: '1',
        tax_value: 0,
        id: null,
        product_id: null,
        product_cost: 0,
        quantity: 0,
        purchase_price: 0,
        product_price: 0,
      },
    ],
    shipping: 0,

    grand_total: 0,
    paid_amount: 0,
    payment_type: 1,
    notes: '',
    status: 1,
  })
  const [dataSaleUpdated, setDataSaleUpdated] = useState<any>({
    id: null,
    date: '',
    warehouse_id: null,
    customer_id: null,
    discount: 0,
    tax_rate: 0,
    sale_items: [
      {
        discount_amount: 0,
        discount_type: '1',
        discount_value: 0,
        net_unit_cost: 0,
        tax_amount: 0,
        tax_type: '1',
        tax_value: 0,
        id: null,
        product_id: null,
        product_cost: 0,
        quantity: 0,
        purchase_price:0,
        product_price: 0,
      },
    ],
    shipping: 0,
    grand_total: 0,
    paid_amount: 0,
    payment_type: 1,
    payment_status: 1,
    token:'',
    notes: '',
    status: 'valide',
  })
  const dataUser:any =  localStorage.getItem("kt-auth-react-v")
  const currentUser2=JSON.parse(dataUser)

  const hasPermission = (permission:any) => {
    return currentUser2.data.permissions.includes(permission)
  };
  const callApi = useCallApi()

  const getCategories = async () => {
    const {data} = await callApi({
      route: 'api/product-categories?pagination=false',
      method: 'GET',
    })
    setCategories(data.data)
    // setLoading(false)
  }
  const getInventories = async () => {
    const {data} = await callApi({
      route: 'api/inventories',
      method: 'GET',
    })
    setInventories(data.data)
    // setLoading(false)
  }
  const getClients=async()=>{
    const data = await callApi({
      route:`api/customers`,
      method: 'GET',
    })
    console.log(data.data);
    
    setClients(data.data.data)
  }
  const getBrands=async()=>{
    const data = await callApi({
      route:`api/brands?pagination=false`,
      method: 'GET',
    })
    console.log(data.data);
    
    setBrands(data.data.data)
  }

  
  const getSuppliers=async()=>{
    const data = await callApi({
      route:`api/suppliers?pagination=false`,
      method: 'GET',
    })
    console.log(data.data);
    
    setSuppliers(data.data.data)
  }

  useEffect(() => {
   getClients()
   getInventories()
   getSales()
   getBrands()
   getSuppliers()
   getCategories()
  }, [])
  const getProducts = async () => {
    const {data} = await callApi({
      route: 'api/products?page[size]=60',
      method: 'GET',
    })
    setProducts(data.data)
    // setLoading(false)
  }
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    localStorage.removeItem("products")
  }
  const getPurchases = async () => {
    try {
      
     
        const data = await callApi({
          route: `api/purchases/${id}?filter[type]=${comptabilise}`,
          method: 'GET',
        })
        
        setDataUpdated({
          ...dataUpdated,
          id: data.data.data.id,
          date: data.data.data.attributes.date,
          supplier_id: data.data.data.attributes.supplier_id,
          warehouse_id: data.data.data.attributes.warehouse_id,
          discount: data.data.data.attributes.discount,
          tax_rate: data.data.data.attributes.tax_rate,
          purchase_items: data.data.data.attributes.purchase_items.data,
          shipping: data.data.data.attributes.shipping,
          notes: data.data.data.attributes.notes,
          grand_total: data.data.data.attributes.grand_total,
          status: data.data.data.attributes.status,
          type:data.data.data.attributes.type

        })
      

      // setDetailP(data.data.data)
      // states.setTotalItems(data.meta.total)
      // states.setTotalPages(Math.ceil(states.totalItems / 100))
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  const getSales = async () => {
    try {
      
     
        const data = await callApi({
          route: `api/sale_invoiceds/${id}`,
          method: 'GET',
        })
        
        setDataSaleUpdated({
          ...dataSaleUpdated,
          id: data.data.data.id,
          date: data.data.data.attributes.date,
          customer_id: data.data.data.attributes.customer_id,
          warehouse_id: data.data.data.attributes.warehouse_id,
          discount: data.data.data.attributes.discount,
          tax_rate: data.data.data.attributes.tax_rate,
          sale_items: data.data.data.attributes.sale_items.data,
          shipping: data.data.data.attributes.shipping,
          notes: data.data.data.attributes.notes,
          grand_total: data.data.data.attributes.grand_total,
          status: data.data.data.attributes.status,
          payment_type: data.data.data.attributes.payment_type,
          payment_status: data.data.data.attributes.payment_status,
          token: data.data.data.attributes.token,

        })
      

      // setDetailP(data.data.data)
      // states.setTotalItems(data.meta.total)
      // states.setTotalPages(Math.ceil(states.totalItems / 100))
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
 
  useEffect(() => {
    
    getProducts()
// alert("hhh")
  }, [currentUser])
 
  
  useEffect(() => {
    if(products.length>0){
      localStorage.setItem("products",JSON.stringify(products))
       
    }
    
  }, [products])
  

  useEffect(() => {

    getPurchases()
  }, [comptabilise])
  return (
    <AuthContext.Provider
      value={{
        getInventories,
        products,
        currentPage,
        totalPages,
        brands,
        suppliers,
        totalItems,
        setCurrentPage,
        setTotalPages,
        setTotalItems,
        getBrands,
        getSuppliers,
        clients,
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        numCmd,
        setNumCmd,
        categories,
        dataUpdated,
        setDataUpdated,
        dataSaleUpdated,
        setDataSaleUpdated,
        comptabilise,
        setComptabilise,
        date,
        setDate,
        getProducts,
        hasPermission,getCategories,tab,setTab
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: any) => {
      try {
        if (!didRequest.current) {
          // const {data} = await getUserByToken(apiToken)
          // if (data) {

          setCurrentUser(auth?.data.user)
          // }
        }
      } catch (error) {
        console.error(didRequest.current)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth?.data?.user) {
      requestUser(auth)
    } else {
      logout()
      setShowSplashScreen(false)
    }

    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
