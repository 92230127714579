import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {useCallApi} from '../../modules/utils'
import ComboBox from '../ComboBox'
import {Loading} from '../Loading'

type Props = {
  data: any
  deleteRow: () => void
  someInfo: any
  update: boolean
  index: number
  calculateGrandTotal: () => void
}
export const ItemProductSale: React.FC<Props> = ({
  update,
  data,
  deleteRow,
  someInfo,
  index,
  calculateGrandTotal,
}) => {
  const callApi = useCallApi()
  const [subtotal, setsubtotal] = useState<number>(data?.data?.attributes.sub_total || 0)
  const [product, setProduct] = useState<any>()
  const [quantity, setQuantity] = useState<number>(data?.data?.attributes?.quantity || 0)
  const [purchase_price, setPurchase_price] = useState<any>(data?.data?.attributes?.purchase_price)
  const [tva, setTVA] = useState<any>(data?.data?.attributes?.tva)  
  const [percent, setPercent] = useState<number>(40)  

  const [product_price, setProductPrice] = useState<any>(data?.data?.attributes?.product_price)

  const updatePurchaseItem = (index: number, field: string, value: any) => {
    const updatedPurchaseItems = [...someInfo.dataSaleUpdated.sale_items]
    updatedPurchaseItems[index][field] = value

    someInfo.setDataSaleUpdated({
      ...someInfo.dataSaleUpdated,
      sale_items: updatedPurchaseItems,
    })
  }
  

  

  useEffect(() => {
      setPurchase_price(parseFloat(product?.attributes?.price_purchase) );
      setProductPrice(product?.attributes?.product_price)
      setTVA(product?.attributes?.tva)
      updatePurchaseItem(index, 'product_id', product?.id)
      updatePurchaseItem(index, 'purchase_price', product?.attributes?.price_purchase)
      updatePurchaseItem(index, 'product_price', product?.attributes?.product_price)

  }, [product])

  useEffect(() => {
      setsubtotal(update ? data?.data?.attributes.sub_total : 0)

      setProductPrice(
        update ? data?.data?.attributes?.product_price : product?.attributes?.product_price
      )
      setPurchase_price(
        update ? data?.data?.attributes?.purchase_price : product?.attributes?.price_purchase
      )   
      setQuantity(update ? data?.data?.attributes?.quantity : product?.attributes?.quantity)
  }, [data])

  const handleKeyPress = (event: any) => {
    const keyCode = event.keyCode || event.which
    const keyValue = String.fromCharCode(keyCode)

    // Allow only numeric characters and control keys (backspace, delete, arrows)
    if (!/^\d$/.test(keyValue) && !event.ctrlKey && !event.metaKey) {
      event.preventDefault()
    }
  }

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseInt(event.target.value)
    setQuantity(newQuantity)

    const updatedPurchaseItems = [...someInfo.dataSaleUpdated.sale_items]
    const price = product_price + (product_price*(tva/100))
    console.log(price);
    
    updatedPurchaseItems[index]['sub_total'] =(price * newQuantity )

    someInfo.setDataSaleUpdated({
      ...someInfo.dataSaleUpdated,
      sale_items: updatedPurchaseItems,
    })
    setsubtotal(updatedPurchaseItems[index]['sub_total'])
    calculateGrandTotal()
  }

  const handleProductPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const price = parseFloat(event.target.value) + ( parseFloat(event.target.value)*(tva/100))

    const updatedPurchaseItems = [...someInfo.dataSaleUpdated.sale_items]
    updatedPurchaseItems[index]['sub_total'] =
    Math.round(price * updatedPurchaseItems[index]['quantity'])


    someInfo.setDataSaleUpdated({
      ...someInfo.dataSaleUpdated,
      sale_items: updatedPurchaseItems,
    })

    setsubtotal(updatedPurchaseItems[index]['sub_total'])
    calculateGrandTotal()
  }
  
  const handlePurchasePriceChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const newPrice = parseFloat(event.target.value) + (parseFloat(event.target.value) * (percent/100));
    setProductPrice(newPrice)
    
    const updatedPurchaseItems = [...someInfo.dataSaleUpdated.sale_items]
    updatedPurchaseItems[index]['sub_total'] =
    Math.round(newPrice * updatedPurchaseItems[index]['quantity'])
    updatedPurchaseItems[index]['product_price'] =newPrice

    someInfo.setDataSaleUpdated({
      ...someInfo.dataSaleUpdated,
      sale_items: updatedPurchaseItems,
    })

    setsubtotal(updatedPurchaseItems[index]['sub_total'])
    calculateGrandTotal()
  }

  const handlePercentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPercent = parseFloat(event.target.value);
    setPercent(newPercent);  // No need to divide by 100 here
  
    const newPrice = parseFloat(purchase_price) + (parseFloat(purchase_price) * newPercent / 100);
    setProductPrice(newPrice);  // Update the product price
  
    const updatedPurchaseItems = [...someInfo.dataSaleUpdated.sale_items];
    updatedPurchaseItems[index]['sub_total'] = Math.round(newPrice * updatedPurchaseItems[index]['quantity']);
    updatedPurchaseItems[index]['product_price'] = newPrice;
  
    someInfo.setDataSaleUpdated({
      ...someInfo.dataSaleUpdated,
      sale_items: updatedPurchaseItems,
    });
  
    setsubtotal(updatedPurchaseItems[index]['sub_total']);
    calculateGrandTotal();
  };
  

  return (
    <>
      <tr>
        <td>
          <div className='d-flex align-items-center'>
            <div className='d-flex justify-content-start flex-column'>
              <input className='d-none' type='text' value={data?.data?.attributes.id} />
              {update ? (
                data?.data?.attributes?.product_name ? (
                  data?.data?.attributes?.product_name
                ) : (
                  <ComboBox
                    setProduct={setProduct}
                    idProduct={data?.data?.attributes?.product_name}
                    index={index}
                    updatePurchaseItem={updatePurchaseItem}
                  />
                )
              )  : (
                <ComboBox
                  setProduct={setProduct}
                  idProduct={data?.data?.attributes?.product_name}
                  index={index}
                  updatePurchaseItem={updatePurchaseItem}
                />
              )}
              {/* <input className='text-dark fw-bold  d-block fs-6' type="text" value={"P1"} 
                    
                      /> */}
            </div>
          </div>
        </td>
  {/* <td>
      <div className='d-flex align-items-center'>
                   
                    <div className='d-flex justify-content-start flex-column'>
                    <input className='d-none'type="text" value={data?.data?.attributes.id}  />

                   
                     <input readOnly={true} className='text-dark fw-bold  d-block fs-6'type="text" value={ 
                        update ?
                        ( data?.data?.attributes?.in_stock ):
                        (product?.attributes?.in_stock)
                      } 
                      style={{width:"15vh"}}
                    //    onChange={(e)=>{                        
                    //       setMarque(e.target.value)
                    //   }}
                      />
                    
                    </div>
                  </div>
                </td> */}
        {/* <td>
                  <div className='d-flex align-items-center'>
                   
                    <div className='d-flex justify-content-start flex-column'>
                    <input className='d-none'type="text" value={data?.data?.attributes.id}  />

                      <input readOnly={true} className='text-dark fw-bold  d-block fs-6'type="text" value={ 
                        update ?
                        ( data?.data?.attributes?.in_stock_invoiced ):(product?.attributes?.in_stock_invoiced)
                      } 
                      style={{width:"15vh"}}
                    //    onChange={(e)=>{                        
                    //       setMarque(e.target.value)
                    //   }}
                      />                   
                    </div>
                  </div>
                </td> */}
        <td>
          <div className='d-flex align-items-center'>
            <div className='d-flex justify-content-start flex-column'>
              <input className='d-none' type='text' value={data?.data?.attributes.id} />

              <input
                className='text-dark fw-bold  d-block fs-6'
                type='text'
                value={quantity > 0 ? quantity : 0}
                style={{width: '15vh'}}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  handleQuantityChange(e)
                  updatePurchaseItem(index, 'quantity', e.target.value)
                }}
              />
            </div>
          </div>
        </td>

        <td>
          <div className='d-flex align-items-center'>
            <div className='d-flex justify-content-start flex-column'>
              <input
                className='text-dark fw-bold d-block fs-6'
                type='text'
                value={product_price}
                style={{width: '16vh'}}
                onChange={(e) => {
                  handleProductPriceChange(e)
                  setProductPrice(e.target.value)
                  updatePurchaseItem(index, 'product_price', e.target.value)
                }}
              />
            </div>
          </div>
        </td>
        <td>
  <div className='d-flex align-items-center'>
    <div className='d-flex justify-content-start flex-column'>
      <input
        className='text-dark fw-bold d-block fs-6'
        type='number' // Use number type for numeric input
        value={percent} // Ensure the value is bound to the percent state
        style={{ width: '16vh' }}
        onChange={(e) => {
          const newPercent = parseFloat(e.target.value);
          if (!isNaN(newPercent)) {
            setPercent(newPercent); // Update percent state
            const newPrice = parseFloat(purchase_price) + (parseFloat(purchase_price) *(newPercent / 100));
            setProductPrice(newPrice);
              
            const updatedPurchaseItems = [...someInfo.dataSaleUpdated.sale_items];
            updatedPurchaseItems[index]['sub_total'] = Math.round(newPrice * updatedPurchaseItems[index]['quantity']);
            updatedPurchaseItems[index]['product_price'] = newPrice;

            someInfo.setDataSaleUpdated({
              ...someInfo.dataSaleUpdated,
              sale_items: updatedPurchaseItems,
            });

            setsubtotal(updatedPurchaseItems[index]['sub_total']);
            calculateGrandTotal();
          }
        }}
      />
    </div>
  </div>
</td>

        <td>
          <div className='d-flex align-items-center'>
            <div className='d-flex justify-content-start flex-column'>
              <input
                className='text-dark fw-bold d-block fs-6'
                type='text'
                value={purchase_price}
                style={{width: '16vh'}}
                onChange={(e) => {
                  handlePurchasePriceChange(e)
                  setPurchase_price(e.target.value)
                  updatePurchaseItem(index, 'purchase_price', e.target.value)
                }}
              />
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <div className='d-flex justify-content-start flex-column'>
              <input
                className='text-dark fw-bold d-block fs-6'
                type='text'
                value={tva}
                style={{width: '16vh'}}
               
              />
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <div className='d-flex justify-content-start flex-column'>
              <input
                className='text-dark fw-bold  d-block fs-6'
                type='text'
                value={subtotal > 0 ? subtotal.toFixed(2) : 0}
                style={{width: '16vh'}}
                //    onChange={(e)=>{
                //       setMarque(e.target.value)
                //   }}
              />
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-end flex-shrink-0'>
            {/* <button
                      type={'submit'}
                      title='save'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' 
                    //   onClick={()=>updateMarque(id)}
                    >
                      {
                        loading?<div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>:<KTSVG 
                        path='/media/icons/duotune/general/gen005.svg'
                          className='svg-icon-3' />
                      }
                          
                      
                    </button> */}
            <button
              title='drop'
              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
              onClick={deleteRow}
            >
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3 ' />
            </button>
          </div>
        </td>
      </tr>
    </>
  )
}
